// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import users from "@src/views/administration/store/reducer";
import payroll from "@src/views/masterdata/payroll/hours/store/reducer";
import salary from "@src/views/masterdata/salary/hours/store/reducer";
import employee from "@src/views/masterdata/employee/store/reducer";
import fileHeader from "@src/views/upload/store/reducer";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  users,
  payroll,
  salary,
  employee,
  fileHeader,
});

export default rootReducer;
