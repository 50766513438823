// ** Initial State
const initialState = {
  fileHeaders: [],
  total: 1,
  params: {},
  selectedFileHeader: null,
  pending: false,
  headerFiles: [],
  employeesToProcess: [],
  tempSavedFormdata: [],
};

const fileHeaderReduer = (state = initialState, action) => {
  switch (action.type) {
    case "FILEHEADER/GETALL":
      return {
        ...state,
        fileHeaders: action.fileHeaders,
        total: action.totalPages,
        params: action.params,
      };
    case "FILEHEADER/ADD":
      return { ...state, selectedFileHeader: action.fileHeader };
    case "FILEHEADER/DELETE":
      return { ...state };
    case "FILEHEADER/GET":
      return { ...state, selectedFileHeader: action.selectedFileHeader };
    case "FILES/UPLOAD":
      return { ...state };
    case "FILES/PENDING":
      return { ...state, pending: action.status };
    case "FILEHEADER/GETFILES":
      return {
        ...state,
        headerFiles: action.headerFiles,
        employeesToProcess: [],
        tempSavedFormdata: [],
      };
    case "FILES/PROCESSEMPLOYEES":
      return {
        ...state,
        employeesToProcess: action.employeesToProcess,
        tempSavedFormdata: action.tempSavedFormdata,
      };
    default:
      return { ...state };
  }
};

export default fileHeaderReduer;
