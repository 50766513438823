// ** Initial State
const initialState = {
  payrollHoursMapping: [],
  total: 1,
  params: {},
  selectedMapping: null,
  componentMapping: null,
  pending: false,
};

const payrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PAYROLL_HOURS_MAPPING":
      return {
        ...state,
        payrollHoursMapping: action.payrollHoursMapping,
        total: action.totalPages,
        params: action.params,
      };
    case "ADD_MAPPING":
      return { ...state };
    case "DELETE_MAPPING":
      return { ...state };
    case "GET_MAPPING":
      return { ...state, selectedMapping: action.selectedMapping };
    case "UPDATE_MAPPING_ERROR":
      return { ...state, updateError: action.error };
    case "GET_PAYROLL_COMPONENT_MAPPING":
      return { ...state, componentMapping: action.payrollComponentMapping };
    case "UPDATE_PAYROLL_COMPONENT_MAPPING":
      return { ...state };
    case "PAYROLL/PENDING":
      return { ...state, pending: action.status };
    default:
      return { ...state };
  }
};

export default payrollReducer;
