// ** Initial State
const initialState = {
  salaryHoursMapping: [],
  total: 1,
  params: {},
  selectedMapping: null,
  componentMapping: null,
  pending: true,
};

const salaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SALARY/GET_SALARY_HOURS_MAPPING":
      return {
        ...state,
        salaryHoursMapping: action.salaryHoursMapping,
        total: action.totalPages,
        params: action.params,
      };
    case "SALARY/ADD_MAPPING":
      return { ...state };
    case "SALARY/DELETE_MAPPING":
      return { ...state };
    case "SALARY/GET_MAPPING":
      return { ...state, selectedMapping: action.selectedMapping };
    case "GET_SALARY_COMPONENT_MAPPING":
      return { ...state, componentMapping: action.salaryComponentMapping };
    case "UPDATE_SALARY_COMPONENT_MAPPING":
      return { ...state };
    case "SALARY/PENDING":
      return { ...state, pending: action.status };
    default:
      return { ...state };
  }
};

export default salaryReducer;
