// ** Initial State
const initialState = {
  users: [],
  total: 1,
  params: {},
  selectedUser: null,
  roles: [],
  pending: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA":
      return {
        ...state,
        users: action.users,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_USER":
      return { ...state, selectedUser: action.selectedUser };
    case "CLEAR_USER":
      return { ...state, selectedUser: action.selectedUser };
    case "ADD_USER":
      return { ...state };
    case "UPDATE_USER":
      return { ...state };
    case "GET_ALL_ROLES":
      return { ...state, roles: action.roles };
    case "DELETE_USER":
      return { ...state };
    case "USER/PENDING":
      return { ...state, pending: action.status };
    default:
      return { ...state };
  }
};

export default userReducer;
